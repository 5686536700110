<template>
	<div class="login-wrap">
		<div class="ms-login">
			<div class="ms-title">
				<span>易债网</span>
				<span class="type">注册</span>
			</div>

			<!-- 手机验证码登录 -->
			<el-form 
				:model="params" 
				:rules="rules" 
				ref="login" 
				label-width="110px" 
				class="ms-content"
			>
        <!-- 常规项目 -->
				<el-form-item prop="username" label="账号">
					<el-input v-model="params.username" placeholder="账号">
					</el-input>
				</el-form-item>
        <el-form-item prop="password" label="密码">
					<el-input type="password" v-model="params.password" placeholder="密码">
					</el-input>
				</el-form-item>
        <el-form-item prop="rePassword" label="再次输入密码">
					<el-input type="password" v-model="params.rePassword" placeholder="再次输入密码">
					</el-input>
				</el-form-item>

        <!-- 邮箱验证 -->
        <el-form-item prop="email" label="邮箱">
					<el-input v-model="params.email" placeholder="邮箱">
					</el-input>
				</el-form-item>
        <!-- <el-form-item prop="emailCode" label="验证码">
					<el-input placeholder="邮箱验证码" v-model="params.emailCode">
					</el-input>
					<el-button 
						class="input_after"
						:disabled="emailSecond<$options.data().emailSecond" 
						@click="sendEmailCode"
					>{{ emailSecond >= $options.data().emailSecond && '发送验证码' || emailSecond }}</el-button>
				</el-form-item> -->

        <!-- 手机号验证 -->
        <el-form-item prop="mobile" label="手机号">
					<el-input v-model="params.mobile" placeholder="手机号">
					</el-input>
				</el-form-item>
				<el-form-item prop="code" label="验证码">
					<el-input placeholder="验证码" v-model="params.code" @keyup.enter="submitForm()">
					</el-input>
					<el-button 
						class="input_after"
						:disabled="second<$options.data().second" 
						@click="sendCode('register')"
					>{{ second >= $options.data().second && '发送验证码' || second }}</el-button>
				</el-form-item>
			</el-form>

			<div class="login-btn">
				<el-button class="submit" type="primary" @click="submitForm()">注册</el-button>
				<div class="other">
					<el-button type="text" @click="$router.push('/login')">返回登录</el-button>
					<el-button type="text">忘记密码</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import utils from '@/utils'
const { cookie } = utils
export default {
	data() {
		return {
			// 登录参数
			params: {
				username: '',  // 用户名
				password: '',  // 密码
        rePassword: '',  // 再次输入密码
        email: '',  // 邮箱
        emailCode: '',  // 邮箱验证码
				mobile: '',  // 手机号
				code: ''   // 手机验证码
			},
      // 接口返回的校验信息
      apiValidateInfo: {
        code: '', // 手机验证码
        emailCode: '',  // 邮箱验证码
      },

			// 校验规则
			rules: {
				username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur"
				  },
          {
						min: 1,
						max: 16,
						message: '请输入16个字以内用户名',
						trigger: 'blur'
					},
        ],
				password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
				  },
          {
						min: 8,
						max: 16,
						message: '请输入8-16位密码',
						trigger: 'blur'
					},
        ],
        rePassword: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur"
				  },
          { validator: (rule, value, callback) => {
            // if (value === '') {
            //   callback(new Error('请再次输入密码'))
            // } else 
            if (value !== this.params.password) {
              callback(new Error('两次密码不一致'))
            } else {
              callback()
            }
          }, 
          trigger: 'blur' }
        ],
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        emailCode: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					},
					{
						min: 4,
						max: 4,
						message: '请输入4位验证码',
						trigger: 'blur'
					},
          {
            pattern: /^[0-9]{4}$/,
            message: '请输入正确的验证码'
					}
				],
				mobile: [
					{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					},
					{
						min: 11,
						max: 11,
						message: '请输入11位手机号码',
						trigger: 'blur'
					},
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
					}
				],
				code: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur"
					},
					{
						min: 4,
						max: 4,
						message: '请输入4位验证码',
						trigger: 'blur'
					},
          {
            pattern: /^[0-9]{4}$/,
            message: '请输入正确的验证码'
					}
				],
			},

			// 手机验证码得计时器
			second: 60,
			timer: null,

			// 邮箱验证码的计时器
			emailSecond: 60,
			emailTimer: null,
		};
	},
	created() {
		this.$store.commit("clearTags");
	},
	methods: {
		// 提交校验
		submitForm() {
			this.$refs.login.validate(valid => {
				if (valid) {
					this.register()
				} else {
					this.$message.error("请填写完整");
					return false;
				}
			});
		},

		// 注册接口
		async register(){
			const res=await this.$api.register(this.params)
			if (res) {
				// 保存
				// const { userinfo } = res
				// const { token, expires_in, id } =userinfo
				// cookie.setCookie('uid', id, 100)
				// cookie.setCookie('token', token, 100)
				// cookie.setCookie('refreshTime', new Date().valueOf(), expires_in / 60 / 60 / 24 )

				// saveUserInfo(userinfo)
				this.$router.go(-1)
			}
		},


		/* 
			手机验证码部分
		*/
		// 获取手机验证码
		async sendCode(event=''){
			const checkPhone=await this.checkPhone()
			if (!checkPhone) return
			const res=await this.$api.sendCode({
				mobile: this.params.mobile,
				event
			})
			this.setSendCodeTimer()
			// if (res) {
			// }
		},

		// 检测手机号
		checkPhone(){
			return new Promise(resolve=>{
				this.$refs.login.validateField('mobile', (e)=>{
					if (!e) {
						resolve(true)
					} else {
						this.$message.error('须正确填写手机')
						resolve(false)
					}
				})
			})
		},

		// 发送手机验证码
		setSendCodeTimer(){
			clearInterval(this.timer)
			this.timer=setInterval(() => {
				if (this.second <= 0)	{
					clearInterval(this.timer)
					this.second=this.$options.data().second
					return
				}
				this.second--
			}, 1000);
		},


		/* 
			邮件验证码部分
		*/
		// 获取邮箱验证码
		async sendEmailCode(){
			const checkPhone=await this.checkEmail()
			if (!checkPhone) return
			const res=await this.$api.sendEmailCode({
				email: this.params.email,
				event: ''
			})
			if (res) {
				this.setSendCodeTimer()
			}
		},

		// 检测检测邮箱
		checkEmail(){
			return new Promise(resolve=>{
				this.$refs.login.validateField('email', (e)=>{
					if (!e) {
						resolve(true)
					} else {
						this.$message.error('须正确填写邮箱')
						resolve(false)
					}
				})
			})
		},

		// 发送邮件验证码
		setSendEmailCodeTimer(){
			clearInterval(this.emailTimer)
			this.emailTimer=setInterval(() => {
				if (this.emailSecond <= 0)	{
					clearInterval(this.emailTimer)
					this.emailSecond=this.$options.data().emailSecond
					return
				}
				this.emailSecond--
			}, 1000);
		},



	},
	computed: {
	},
	
};
</script>

<style lang="scss" scoped>
.login-wrap {
	// position: relative;
	width: 100%;
	height: 100%;
	background-image: url(../../../public/img/login_bg.jpg);
	background-size: 100%;
	background-repeat: round;
}

.ms-title {
	width: 100%;
	line-height: 50px;
	text-align: center;
	font-size: 20px;
	// color: #fff;
	border-bottom: 1px solid #ddd;
}

.ms-login {
	position: absolute;
	left: 50%;
	top: 50%;
  transform: translate(-50%, -50%);
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.9);
	overflow: hidden;
	width: 460px;
}

.ms-content {
	padding: 30px 30px;
	::v-deep .el-form-item{
		.el-form-item__content{
			display: flex;

			.input_after{
				margin-left: 5px;
			}
		}
	}
}

.login-btn {
	text-align: center;
	padding: 0 30px;
	padding-bottom: 20px;
	.submit {
		width: 100%;
		height: 36px;
		margin-bottom: 10px;
	}

	.other{
		// margin-top: 10px;
		display: flex;
		justify-content: space-between;
		>*{
			padding: 0 10px;
			height: auto;
			min-height: auto;
			width: auto;
		}
		
	}
	::v-deep .set{
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
	 .el-button{
			// padding: 0 10px;
			min-height: auto;
			height: auto;
			width: auto;
		}
		.el-checkbox__label{
			color: #409EFF;
			line-height: 1;
		}
	}
	.el-button--text{
		padding: 0;
	}
}



.login-tips {
	font-size: 12px;
	line-height: 30px;
	color: #fff;
}



</style>
